<template>
  <div class="relative bg-white h-full w-full z-20 w-screen">
    <div class="flex justify-between mt-4 ml-4 mr-4">
      <div class="flex flex-col justify-start items-start">
        <s-h3 class="mb-4">
          Show Only:
        </s-h3>
        <div class="flex items-center">
          <div class="flex items-center">
            <checkbox v-model="filters.hhw" />
            <s-label class="ml-4">
              HHW
            </s-label>
          </div>
          <div class="flex items-center ml-8">
            <checkbox v-model="filters.reuse" />
            <s-label class="ml-4">
              Reuse
            </s-label>
          </div>
        </div>
      </div>
      <ButtonIcon @click="toggleView">
        <MapIcon class="w-8 text-blue" />
      </ButtonIcon>
    </div>
    <DynamicScroller
      v-if="sites && sites.length"
      class="h-full mt-8"
      :items="sites"
      :min-item-size="defaultItemSize"
      key-field="Location_ID"
    >
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[item.expanded]"
          :data-index="index"
        >
          <div class="pl-8 pr-8 pt-4 pb-4">
            <card>
              <SiteDetails
                class="site-item"
                :site="item"
                @switch-to-map="toggleView"
              />
            </card>
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
    <div class="flex flex-col items-center mt-16" v-else>
      <ExclamationIcon class="h-10 w-10 mb-2 text-yellow"></ExclamationIcon>
      <s-h3 v-if="selectedState"
        >There are no sites available within selected state
        {{ selectedState }}</s-h3
      >
      <s-h3 v-else>There are no sites found in current view</s-h3>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import { ExclamationIcon } from '@heroicons/vue/solid';
import { MapIcon } from '@heroicons/vue/outline';
import ButtonIcon from '@/components/atoms/ButtonIcon.vue';
import SLabel from '@/components/atoms/s-label.vue';
import Checkbox from '@/components/atoms/checkbox.vue';
import SH3 from '@/components/atoms/s-h3.vue';
import card from '@/components/atoms/card.vue';
import SiteDetails from '@/components/molecules/SiteDetails.vue';

import { usageReuseFilter, usageHHWFilter, usageReuseHHWFilter } from '@/common/utils/translator.utils';

export default {
  name: 'SiteList',
  components: {
    ButtonIcon,
    MapIcon,
    SLabel,
    Checkbox,
    SH3,
    card,
    SiteDetails,
    ExclamationIcon,
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const sites = computed(() => store.getters.filteredSites);

    const selectedState = computed(() => store.state.selectedState);

    const defaultItemSize = 250;

    const toggleView = () => emit('toggle:view');

    const filterLocation = (site) => `${site.City}, ${site.State}`;

    const filters = reactive({
      hhw: false,
      reuse: false,
    });
    watch(filters, () => {
      if (filters.hhw && filters.reuse) {
        store.commit('APPEND_FILTER', usageReuseHHWFilter);
      } else if (filters.hhw) {
        store.commit('APPEND_FILTER', usageHHWFilter);
      } else if (filters.reuse) {
        store.commit('APPEND_FILTER', usageReuseFilter);
      } else {
        store.commit('RESET_FILTER', 'Pin_Code');
      }
    });

    return {
      sites,
      toggleView,
      filterLocation,
      filters,
      defaultItemSize,
      selectedState,
    };
  },
};
</script>
