<template>
  <div
    class="h-8 w-8 bg-grey-light-1 rounded-xs flex items-center justify-center cursor-pointer"
    :class="{ 'bg-black': checked }"
    @click="updateValue"
  >
    <input
      type="checkbox"
      class="hidden"
      :checked="checked"
    >
    <template v-if="checked">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    modelValue: Boolean,
  },
  setup(props, { emit }) {
    const checked = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value)
      }
    });

    const updateValue = () => {
      const inverse = !props.modelValue;
      emit('update:modelValue', inverse);
    };

    return { checked, updateValue };
  },
};
</script>
